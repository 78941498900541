import { identifierModuleUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class DashboardService {

    getEventRoute = '/api/newevents/';

    constructor(private dataService: DataService) {
    };

    // getDashboard(districtId, blockId, villageId, date, isleastblock, refresh: boolean) {
    //     return this.dataService.getData('/api/dashboardchart/'
    //         + districtId + '/' + blockId
    //         + '/' + villageId + '/' + date + '/' + isleastblock, refresh);
    // }
    getDashboard(data, refresh: boolean) {
        return this.dataService.getData('/api/dashboard/dashboardbarchart/' + data.districtId + '/' + data.blockId, refresh);
    }

    getLookupAreaType(langType: any, refresh: any) {
        return this.dataService.getData('/api/lookup/areaType', refresh)
    }

    getDashBoardData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/dashboard/dashboardchart/' + data.districtId + '/' + data.blockId + '/' + data.sectorId, refresh);
    }

    getMapData(refresh: boolean, districtId, blockId, sectorId, awcId, malNutritionType) {
        return this.dataService.getData('/api/dashboard/mapdata/' + districtId + '/' + blockId + '/' + sectorId + '/' + awcId + '/' + malNutritionType, refresh);
    }

    getMapGeoJsonData(refresh: boolean, districtId, blockId, sectorId, awcId, malNutritionType) {
        return this.dataService.getData('/api/dashboard/mapgeojsondata/' + districtId + '/' + blockId + '/' + sectorId + '/' + awcId + '/' + malNutritionType, refresh);
    }

    getAllDashboardData(data: any, refresh: boolean) {
        return this.dataService.getData('/api/dashboard/dashboarddata/' + data.districtId + '/' + data.blockId + '/' + data.sectorId, refresh);
    }

    getMapGridData(refresh: boolean, districtId, blockId, sectorId, severityType) {
        return this.dataService.getData('/api/dashboard/mapgriddata/' + districtId + '/' + blockId + '/' + sectorId + '/' + severityType, refresh);
    }

    getPlannedActualAdditionalDatas(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/dashboard/mitargetactual/' + data.districtId + '/' + data.blockId, refresh);
    }

    getMiAgegroup(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/dashboard/miagegroup/' + data.districtId + '/' + data.blockId, refresh);
    }

    getGauageChartData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/dashboard/migaugedata/' + data.districtId + '/' + data.blockId, refresh);
    }

    getMiTreatmentChartData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/report/mimediacaltreatment/' + data.districtId + '/' + data.blockId, refresh);
    }

    getmedicalIntervention(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/child/mi/' + data.beneficiaryId, refresh);
    }

    getmiEvaluation(data: any, refresh: boolean) {
        return this.dataService.getData('/api/child/mi/evaluationdetail/' + data.beneficiaryId, refresh);
    }

    getmedicalInterventionList(data: any, refresh: boolean) {
        return this.dataService.getData('/api/child/awc/' + data.awcId, refresh);
    }

    getAliveStatus(refresh: boolean) {
        return this.dataService.getData('/api/lookup/alivestatustype', refresh);
    }

    getDeathStatus(refresh: boolean) {
        return this.dataService.getData('/api/lookup/deceasedcausetype', refresh);
    }

    getbeneficiarystatushistory(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/beneficiarystatushistory/' + data.beneficiaryId, refresh);
    }

    getLastEntrydateofchild(data: any, refresh: boolean) {
        return this.dataService.getData('/api/beneficiarystatushistory/lastinspection/' + data.beneficiaryId, refresh);
    }

    beneficiaryStatusSave(result: any) {
        return this.dataService.post('/api/beneficiarystatushistory', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    getDashboardData(districtId: any, blockId: any, municipalityId: any, date: any, refresh: boolean) {
        return this.dataService.getData('/api/dashboard/eventstatusforweb/' + districtId + '/' + blockId + '/' + municipalityId + '/' + date, refresh);
    }

    getDistrictWiseBeneficiaryData(eventType: any, areatType: any, districtId: any, blockId: any, municipalityId: any, date: any, refresh: boolean) {
        debugger;
        if(districtId=="--All--District--"){
            var district=0
        }
        if(municipalityId==undefined){
            var municipality=0
        }
        return this.dataService.getData('/api/dashboard/districtwiselist/' + eventType + '/' + areatType + '/' + district + '/' + blockId + '/' + municipality + '/' + date, refresh);
    }

    getHolidays(districtId: number, refresh: boolean) {
        return this.dataService.getData('/api/holiday/' + districtId, refresh)
    }

    // getSchoolsData(districtId: any, blockId: any, municipalityId: any, villagepanchayatId: any, zoneId: any, cookingCenterId: any, eventType, areaType: any, langType: any, date: any, refresh: boolean) {
    //     return this.dataService.getData('/api/school/schoollistbyprogress/' + districtId + '/' + blockId + '/' + municipalityId + '/' + villagepanchayatId + '/' + zoneId + '/' + cookingCenterId + '/' + eventType + '/' + areaType + '/' + langType + '/' + date, refresh);
    // }

    getSchoolsData(result: any) {
        return this.dataService.post('/api/school/schoollistbyprogress', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    getHeatmapData(selectedDate: Date, refresh: boolean) {
        return this.dataService.getData('/api/dashboard/heatmap/' + selectedDate, refresh)
    }

    getSchoolEventTimeline(schoolId: any, langType: any, date: any, refresh: boolean) {
        return this.dataService.getData('/api/school/timelineschoolevent/' + schoolId + '/' + langType + '/' + date, refresh)
    }
    getCookingCenterEventTimeLine(cookingCenterId: any, langType: any, date: any, refresh: boolean) {
        return this.dataService.getData('/api/cookingcenter/timelinecookingcenterevent/' + cookingCenterId + '/' + langType + '/' + date, refresh);
        // return this.dataService.getData('/api/cookingcenter/timelinecookingcenterevent/'+ cookingCenterId +'/'+{langType}/{date})
    }

    getRouteTable(districtId: number, municipalityId: number, langType: number, refresh: boolean) {
        return this.dataService.getData('/api/route/ccroutes/' + districtId + '/' + municipalityId + '/' + langType, refresh)
    }

    getById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/user/driver/' + id, refresh);
    }

    getTimelineOfVehicle(userId: number, routeId: number, refresh: boolean) {
        return this.dataService.getData('/api/event/drivertripevent/' + userId + '/' + routeId, refresh);
    }
}


//'/api/dashboard/districtwiselist/{eventType}/{areatType}/ {districtId}/ {blockId}/ {municipalityId}/ {date}'