import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ReportsService } from 'src/app/services/reports.service';
@Component({
  selector: 'app-show-districtwisereport',
  templateUrl: './show-districtwisereport.component.html',
  styleUrls: ['./show-districtwisereport.component.scss']
})
export class ShowDistrictwisereportComponent implements OnInit {
  routeParams: any;
  reportUrl: any;
  submitbtn: string;
  title: string;
  @Input()
  url: SafeResourceUrl;
  src: string;
  pdfSource: any;
  src1: any;
  loading = true;
  titletxt: any;
  headerTitle: string;

  constructor(
    private reportService: ReportsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowDistrictwisereportComponent>,
    public sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.data.downloadType = 4;
    this.reportService.tripreport(this.data).subscribe(result => {
      if (result) {
        const decodedStringAtoB = atob(result);
        this.src1 = this.sanitizer.bypassSecurityTrustHtml(decodedStringAtoB);
        this.loading = false;
      }
    });
    let msg = this.translate.instant(this.data.reportType == 1 ? "Daily Status Report For Cooking Center" : "Daily Status Report For School")
    this.titletxt = this.data.reportType == 1 ? "Daily Status Report For Cooking Center" : "Daily Status Report For School";
    this.headerTitle = this.data.reportType == 1 ? "Cooking Center Wise Daily Status Report" : "School Wise Daily Status Report";
  }

  downloadpdf() {
    this.loading = true;
    const pdfdata = this.data;
    pdfdata.downloadType = 1;
    this.reportService.tripreport(pdfdata).subscribe(result => {
      this.loading = false;
      if (result) {
        this.pdfSource = result;
        const linkSource = 'data:application/pdf;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.titletxt + '.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  downloadexcel() {
    this.loading = true;
    const printdata = this.data;
    printdata.downloadType = 2;
    this.reportService.tripreport(printdata).subscribe(result => {
      this.loading = false;
      if (result) {
        const linkSource = 'data:application/vnd.ms-excel;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.titletxt + '.xls';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  downloadhtml() {
    this.loading = true;
    const printdata = this.data;
    printdata.downloadType = 4;
    this.reportService.tripreport(printdata).subscribe(result => {
      this.loading = false;
      if (result) {
        const linkSource = 'data:text/html;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.titletxt + '.html';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  downloadword() {
    this.loading = true;
    const worddata = this.data;
    worddata.downloadType = 3;
    this.reportService.tripreport(worddata).subscribe(result => {
      this.loading = false;
      if (result) {
        const linkSource = 'data:application/msword;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.titletxt + '.doc';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  print() {
    const exceldata = this.data;
    exceldata.downloadType = 1;
    this.reportService.tripreport(exceldata).subscribe(result => {
      if (result) {
        this.printPreview(result);
      }
    });
  }

  printPreview = (data, type = 'application/pdf') => {
    let blob = null;
    blob = this.b64toBlob(data, type);
    const blobURL = URL.createObjectURL(blob);
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
    theDoc.body.appendChild(theScript);
  };

  b64toBlob = (content, contentType) => {
    contentType = contentType || '';
    const sliceSize = 512;
    // method which converts base64 to binary
    const byteCharacters = window.atob(content);

    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType
    }); // statement which creates the blob
    return blob;
  };

  onCancel() {
    this.dialogRef.close();
  }

  close(isProceed) {
    this.dialogRef.close(isProceed);
  }

}