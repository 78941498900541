import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import * as moment from "moment";
import { UserSessionService } from 'src/app/services/usersession.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  public nameForm: FormGroup;
  myusername: string = "";
  selected: Date | null;
  myFilter: any;
  myFilterholiday: any;
  districtId: any;


  constructor(private dashboardService: DashboardService, private formBuilder: FormBuilder,
    private userSessionService: UserSessionService) {
    this.nameForm = this.formBuilder.group({
      holidayDate: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
      hasCompensatory: new FormControl('', Validators.required),
      compensatoryDate: new FormControl('', Validators.required),
    });
    
  //   debugger
  //  this.districtId =( this.userSessionService.getDistrictId())
  }

  ngOnInit(): void {
    this.dashboardService.getHolidays(1, true).subscribe(res => {

      this.myFilter = (d: Date | null): boolean => {
        console.log(d)
        const year = (d || new Date()).getFullYear();
        const month = (d || new Date()).getMonth();
        const date = (d || new Date()).getDate();

        let isHoliday: boolean = true

        res?.forEach(day => {
          const calendarYear = +day.date.slice(0, 4)
          const calendarMonth = +day.date.slice(5, 7) - 1
          const calendarDate = +day.date.slice(8, 10)
          if (calendarYear === year && calendarMonth === month && calendarDate === date && day.isHoliday) {
            isHoliday = false;
          }
        })

        return isHoliday;
      };

        this.dashboardService.getHolidays(1, false).subscribe(res => {
    
          this.myFilterholiday = (d: Date | null): boolean => {
            console.log(d)
            const year = (d || new Date()).getFullYear();
            const month = (d || new Date()).getMonth();
            const date = (d || new Date()).getDate();
    
            let isHoliday: boolean = false
    
            res?.forEach(day => {
              const calendarYear = +day.date.slice(0, 4)
              const calendarMonth = +day.date.slice(5, 7) - 1
              const calendarDate = +day.date.slice(8, 10)
              if (calendarYear === year && calendarMonth === month && calendarDate === date && day.isHoliday) {
                isHoliday = true;
              }
            })
    
            return isHoliday;
          };
        })
    })
    
  }

  
  Compensatory: string;
  Compensatoryleave: string[] = ['Yes', 'No'];

  submit() {
    console.log("submit called");

    this.myusername = this.nameForm.value;
    let data = {
      districtId: JSON.parse(localStorage.getItem('districtId')),
      holiDayDate: this.nameForm.get('holidayDate').value,
      compWorkingDate: this.nameForm.get('compensatoryDate').value ? this.nameForm.get('compensatoryDate').value : null,
      description: this.nameForm.get('reason').value,
    }
  //   this.dashboardService.postHoliday(data).subscribe(result =>{
  //  console.log(result);
   
  //   });

  }

}
