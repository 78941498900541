import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guard/auth.guard";
import { BaseComponent } from "./base/base.component";

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./../../views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
      },
      {
        path: 'users',
        loadChildren: () => import('./../../views/pages/master/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'profile/:id/:actionInfo',
        loadChildren: () => import('./../../views/pages/profile/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'monthlyreport',
        loadChildren: () => import('./../../views/pages/reports/monthlyreport/monthlyreport.module').then(m => m.MonthlyReportModule)
      },
      {
        path: 'genderwisechildcount',
        loadChildren: () => import('./../../views/pages/reports/genderwisechildcountreport/genderwisechildcountreport.module').then(m => m.GenderWiseChildcountReportModule)
      },
      {
        path: 'district',
        loadChildren: () => import('./../../views/pages/master/district/district.module').then(m => m.DistrictModule)
      },
      {
        path: 'block',
        loadChildren: () => import('./../../views/pages/master/block/block.module').then(m => m.BlockModule)
      },
      {
        path: 'sector',
        loadChildren: () => import('./../../views/pages/master/sector/sector.module').then(m => m.SectorModule)
      },
      {
        path: 'awc',
        loadChildren: () => import('./../../views/pages/master/awc/awc.module').then(m => m.AwcModule)
      },
      {
        path: 'grid',
        loadChildren: () => import('./../../views/pages/grid/grid.module').then(m => m.gridModule)
      },
      {
        path: 'cookingcentrewisereport',
        loadChildren: () => import('./../../views/pages/reports/district-wise-report/district-report.module').then(m => m.DistrictReportModule)
      },
      {
        path: 'schoolwisereport',
        loadChildren: () => import('./../../views/pages/reports/schoolwisereport/schoolwisereport.module').then(m => m.SchoolReportModule)
      },
      {
        path: 'holidaycalendar',
        loadChildren: () => import('./../../views/pages/holidaycalendar/holidaycalendar.module').then(m => m.HolidaycalendarModule)
      },
      {
        path: 'vehicletracking',
        loadChildren: () => import('./../../views/pages/vehicleTracking/vehicleTracking.module').then(m => m.VehicleTrackingModule)
      },
    ],
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
