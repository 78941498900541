export const environment = {



  // apiBaseUrl: 'http://localhost:7000',     //live

  apiBaseUrl: 'https://mmsapi.xenovex.com',     //dev.org
  imageBaseUrl: 'https://mmsimages.xenovex.com/',
  // signalrUrl: 'http://localhost:5255/notifyHub',
  signalrUrl: 'http://cmbfssignalr.tnega.org:8000/notifyhub',


  tokenEndPoint: '/api/token',
  production: false,
  environment: 'Local',
  showEnvironment: true,
};
