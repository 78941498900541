import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
    selector: 'app-popuptimeline',
    templateUrl: './popuptimeline.component.html',
    styleUrls: ['./popuptimeline.component.scss']
})
export class PopupTimelineComponent implements OnInit {
    routeData: any = [];
    cookingCenter: string;
    routeName: string;
    formData: any;
    vehicleNo: any;
    driverName: any;
    mobileNo: any;
    tableData: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dataRecievedToAdd: any,
        public dialogRef: MatDialogRef<PopupTimelineComponent>,
        public dashboardService: DashboardService,
        public navigationService: NavigationService,
    ) {
        this.formData = this.dataRecievedToAdd.formData;
        this.tableData = this.dataRecievedToAdd.tableData;
    }

    ngOnInit(): void {
        this.timelineData();
        this.cookingCenter = this.formData?.schoolDetails[0]?.cookingcenterName;
        this.routeName = this.formData?.routeName;
        this.vehicleNo = this.formData?.regNo;
        this.driverName = this.formData?.driverName;
        this.mobileNo = this.formData?.mobileNo;
    }

    timelineData() {
        this.dashboardService.getTimelineOfVehicle(this.tableData.driverId, this.tableData.routeId, true).subscribe((result) => {
            result.forEach(element => {
               element.startTime = moment(element.startTs).format('YYYY-MM-DD HH:mm:ss');
            });
            this.routeData = _.orderBy(result, ["startTime"], ["asc"]);
            console.log(this.routeData);
            
        });
    }

    getDays(item1, item2) {
        var firstDate = moment(item1);
        var secondDate = moment(item2);
        var diffInMins = Math.abs(secondDate.diff(firstDate, 'm'));
        if (diffInMins === 1) {
            return diffInMins + '-' + "min";
        }
        else if (diffInMins === 0) {
            return '';
        }
        else {
            return diffInMins + '-' + "mins";
        }
    }

    onCancel() {
        this.dialogRef.close();
    }

}
