import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-showmonthlyreport',
  templateUrl: './showmonthlyreport.component.html',
  styleUrls: ['./showmonthlyreport.component.scss']
})
export class ShowMonthlyReportComponent implements OnInit {
  routeParams: any;
  reportUrl: any;
  submitbtn: string;
  title: string;

  @Input()
  url: SafeResourceUrl;
  src: string;
  pdfSource: any;
  src1: any;
  loading = true;

  constructor(
    private reportService: ReportsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowMonthlyReportComponent>,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.data.downloadType = 4;
    this.reportService.tripreport(this.data).subscribe(result => {
      console.log(result);
      
      if (result) {
        const decodedStringAtoB = atob(result);
        this.src1 = this.sanitizer.bypassSecurityTrustHtml(decodedStringAtoB);
        console.log(this.src1);
        
        this.loading = false;
      }
    });
  }

  downloadpdf() {
    const pdfdata = this.data;
    pdfdata.downloadType = 1;
    this.reportService.tripreport(pdfdata).subscribe(result => {
      if (result) {
        this.pdfSource = result;
        const linkSource = 'data:application/pdf;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = 'monthlyreport.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  downloadexcel() {
    const printdata = this.data;
    printdata.downloadType = 2;
    this.reportService.tripreport(printdata).subscribe(result => {
      if (result) {
        const linkSource = 'data:application/vnd.ms-excel;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = 'Monthly Report.xls';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  downloadhtml() {
    const printdata = this.data;
    printdata.downloadType = 4;
    this.reportService.tripreport(printdata).subscribe(result => {
      if (result) {
        const linkSource = 'data:text/html;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = 'Monthly Report.html';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  downloadword() {
    const worddata = this.data;
    worddata.downloadType = 3;
    this.reportService.tripreport(worddata).subscribe(result => {
      if (result) {
        const linkSource = 'data:application/msword;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = 'Monthly Report.doc';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  print() {
    const exceldata = this.data;
    exceldata.downloadType = 1;
    this.reportService.tripreport(exceldata).subscribe(result => {
      if (result) {
        this.printPreview(result);
      }
    });
  }

  printPreview = (data, type = 'application/pdf') => {
    let blob = null;
    blob = this.b64toBlob(data, type);
    const blobURL = URL.createObjectURL(blob);
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
    theDoc.body.appendChild(theScript);
  };

  b64toBlob = (content, contentType) => {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = window.atob(content);

    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType
    });
    return blob;
  };

  onCancel() {
    this.dialogRef.close();
  }

  close(isProceed) {
    this.dialogRef.close(isProceed);
  }

}
